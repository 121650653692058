#loginclass{
 background: url("../images/office-building.jpeg") ;
 background-size: 100% 100%;
 display: flex;
 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
  
 /* -moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */
 animation:  changePage5BackgroundImage  50s infinite ease-in-out;
}



@keyframes changePage5BackgroundImage {
    0% {
      background-image: url("../images/office-building.jpeg") ;
      /* background-image: url('https://picsum.photos/id/1015/1500/1000'); */
    
    }
    25% {
      background-image: url("../images/bullet-train.jpeg") ;
    
    }
    50% {
      background-image: url('../images/airplane.jpeg');
    }
    75% {
      background-image: url('../images/login-change-bk3.jpg');
    }
    100% {
      background-image: url('../images/login-change-bk4.jpg');
    }
  }
  