
/*for mobile */
@media (min-width: 50px) and (max-width: 480px) {

}
/*For tablets */
@media (min-width: 481px) and (max-width: 1024px) {
}


/*social followup star */
.social-container {
  position: relative;
    background: white;
    padding: 0px 0px;
    z-index: 10;
    margin-left: 10vw;
  }
  a.social {
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
  }
  a.social:hover {
    transform: translateY(-2px);
  }
  a.youtube {
    color: #eb3223;
  }
  
  a.facebook {
    color: #4968ad;
  }
  
  a.twitter {
    color: #49a1eb;
  }
  
  a.instagram {
    color: black;
  }
  /*social followup end */


  
.textcopyright{

  transform: translate(0%,-0%);
  text-transform: uppercase;
  font-family: verdana;
  font-size: 1em;
  font-weight: 700;
  color :#500707;
  z-index: 99;
  text-shadow: 1px 1px 1px #919191,
      1px 2px 1px #919191,
      1px 3px 1px #919191,
      1px 4px 1px #919191,
      1px 5px 1px #919191,
      1px 6px 1px #919191,
      1px 7px 1px #919191,
      1px 8px 1px #919191,
      1px 9px 1px #919191,
      1px 10px 1px #919191,
  1px 18px 6px rgba(16,16,16,0.4),
  1px 22px 10px rgba(16,16,16,0.2),
  1px 25px 35px rgba(16,16,16,0.2),
  1px 30px 60px rgba(16,16,16,0.4);
 
}